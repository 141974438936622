.analyticsDetails {
  padding: 10px !important;
  background-color: #191c24;
  border-radius: 5px;
}
.analyticsBottom {
  height: 220px;
  margin-bottom: 5px;
}
.analyticsTop {
  height: 200px;
}
.analyticsRight {
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  align-items: center;
  margin: 0%;
  border-radius: 5px;
  height: 183px;
}
.consumptionunit {
  position: relative;
  top: -50%;
  text-align: center;
  font-size: 2px;
}
.ddt_bottom{
  width: 49.5%;
}
.ddt_chartdata{
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 600px){
  .container_with_shadow_top{
    margin-top: 20px;
    margin-left: 12px;
  }
  .ddt_chartdata{
    display: block;
    margin-bottom: 15px;
  }
  .ddt_bottom{
    width: 100%;
    height: 100%;
  }
  .datagrid{
    width: 100%;
  }
}
