
@media screen and (max-device-width: 480px)
and (orientation: portrait){
    .paperstyle{
        background-color: aqua;
    }
}
.menuStyle{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}